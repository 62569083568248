var ctrl = function () {
};

ctrl.bind = function () {
    var $body = $('body');
    var $scope = $('.js-document-form');

    $scope.on('click', '.year_js .btPrevNext', function () {
        var year = parseFloat($('input.js-year').val());
        var newYear = ctrl.computeValue(year, $(this));
        $('input.js-year')
            .val(newYear)
            .trigger('change')
        ;
        $scope.submit();
    });

    $scope.on('click', '.js-page-btn', function () {
        var page = parseFloat($('input.js-page').val());
        var newPage = ctrl.computeValue(page, $(this));
        $('input.js-page')
            .val(newPage)
            .trigger('change')
        ;
        $scope.submit();
    });

    $scope.on('change', '.js-type', function () {
        $scope.submit();
    });

};

ctrl.computeValue = function (value, btn) {
    var newValue = value - 1;

    if (btn.hasClass('next'))
        newValue = value + 1;

    return newValue;
};

module.exports = ctrl;