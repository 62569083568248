var ctrl = function() {};

ctrl.bindSearchForms = function () {
    var self = this;
    $('.filter_form_js').each(function() {
        self.bindSearchForm($(this));
    });
};
ctrl.bindSearchForm = function($form) {

    // bind checkbox discipline
    var $discipline_all_checkbox = $('.checkbox_discipline_js[value="all"]', $form);
    var $discipline_checkbox = $('.checkbox_discipline_js[value!="all"]', $form);

    $discipline_all_checkbox.change(function() {
        $discipline_checkbox.prop('checked', false);
        $discipline_checkbox.parent().removeClass('isChecked');
        $form.submit();
    });

    $discipline_checkbox.change(function() {
        $discipline_all_checkbox.prop('checked', false);
        $discipline_all_checkbox.parent().removeClass('isChecked');
        $form.submit();
    });

    var yearOptions = typeof $('.year_js').data('options') != 'undefined'
        ? $('.year_js').data('options')
        : false;

    // bind year
    var incrYear = function() {
        var currentYear = parseInt($('.year_js .value', $form).html());

        if (yearOptions == false) { // change year without check yearOptions
            setYear(currentYear + 1);
        } else { // use yearOptions to change year
            var idx = yearOptions.indexOf(currentYear);
            if (yearOptions.length > idx) {
                setYear(yearOptions[idx + 1]);
            }
        }
    };

    var decrYear = function() {
        var currentYear = parseInt($('.year_js .value', $form).html());

        if (yearOptions == false) { // change year without check yearOptions
            if (currentYear < 1) {
                currentYear = 1;
            }
            setYear(currentYear - 1);
        } else { // use yearOptions to change year
            var idx = yearOptions.indexOf(currentYear);
            if (0 < idx) {
                setYear(yearOptions[idx - 1]);
            }
        }
    };

    var setYear = function(year) {
        $('.year_js .value', $form).html(year);
        $('.year_input_js', $form).val(year );
        console.log('set year to ', year);
    };

    $('.year_js .prev', $form).click(function() {
        decrYear();
        $form.submit()
    });

    $('.year_js .next', $form).click(function() {
        incrYear();
        $form.submit();
    });

    // bind custom
    var $elt = $('[data-submit-on]', $form);
    $elt.each(function() {
        var action = $(this).data('submit-on');
        $(this).on(action, function() {
            $form.submit();
        })
    });
};

module.exports = ctrl;