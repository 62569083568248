// var $ = require('jquery');

//load jquery collection
require('components/Collection');

var Typeahead = require('components/Typeahead');
var ImageUpload = require('components/ImageUpload');
var Cropper = require('components/Cropper');
var formUtils = require('bindings/formUtils');
var Net = require('core/Net');
var FormCollection = require('components/FormCollection');

function initAutocomplete(elem) {
    var that = $(elem);
    that.typeahead({
        hint: true,
        highlight: true
    }, {
        source: new Bloodhound({
            datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            remote: {
                url: that.data('route') + '?qs=_qs_',
                wildcard: '_qs_'
            }
        }),
    })
}

$.fn.mobilenumber = function() {
    return this.each(function() {
        this.input = $(this);
        this.parent = $(this.input.parent());
        this.minus = this.parent.find('.js-minus');
        this.plus = this.parent.find('.js-plus');
        var that = this;

        this.minus.click(function () {
            that.input.val(that.getValue() - 1);
        })

        this.plus.click(function () {

            that.input.val(that.getValue() + 1);
        })

        this.getValue = function() {
            return (that.input.val()) ? parseFloat(that.input.val()) : 0;
        }
    });
};

module.exports = function (form_selector, kw, additonnal_call) {
    kw = kw || {};
    ImageUpload.initialize($(form_selector));

    FormCollection.bindAll($(form_selector));

    var ckeditor_config = {
        extraPlugins: "oembed,widget,lineutils,wordcount,notification",
        wordcount: {showParagraphs: true, showWordCount: true, showCharCount: true, countSpacesAsChars: true},
        allowedContent: true,
        uiColor: '#eeeeee',
        customConfig: '',
        language: 'fr',
        toolbar: [
            ['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat'],
            ['NumberedList', 'BulletedList'],
            ['Link', 'Unlink', 'Image'],
            ['oembed']
        ]
    };
    if (kw && kw.ckeditor) {
        var ckeditor_config = _.assign({
            extraPlugins: "dialogui,dialog,oembed,widget,lineutils,wordcount,notification",
            wordcount: {showParagraphs: true, showWordCount: true, showCharCount: true, countSpacesAsChars: true},
            allowedContent: true,
            uiColor: '#eeeeee',
            customConfig: '',
            on: {
                dialogShow: function (evt) {
                    var dialog = evt.data;

                    if (dialog.getName() == 'image')
                        dialog.setValueOf('info', 'txtWidth', '100%');
                }
            },
            language: 'fr',
            toolbar: [
                ['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat'],
                ['NumberedList', 'BulletedList'],
                ['Link', 'Unlink', 'Image'],
                ['oembed']
            ]
        }, kw.ckeditor);
    }


//    $(form_selector + ' .js-richtext').each(function (idx, elem) {
//        CKEDITOR.replace(elem, {
//
//        });
//    });
    if(typeof CKEDITOR != "undefined") {
        $(form_selector + ' .js-richtext').ckeditor(ckeditor_config);
    }

    $(form_selector + ' .js-counttext').keyup(function() {
        var length = $(this).val().length;
        $(this)
            .closest('div')
            .find('.js-inputlength').html(length);
    })

    $(form_selector + ' .js-mobilenumber').mobilenumber();


    $('.js-fileupload').fileupload({
        dataType: 'json',
        formData: {},
        maxFileSize: 5000000,
        add: function (e, data) {
            var fileContainer = $(this).closest('.js-file');
            formUtils.uploadStarted(fileContainer);
            data.submit();
        },
        done: function (e, data) {
            var fileContainer = $(this).closest('.js-file');
            $.each(data.result.files, function (index, file) {
                formUtils.addFile(fileContainer, file);
            });

        },
        fail: function (e, data) {
            var fileContainer = $(this).closest('.js-file');
            formUtils.uploadFinished(fileContainer);
        },
        progressall: function (e, data) {
            return;

            var progress = parseInt(data.loaded / data.total * 100, 10);
            updateProgressFile(fileId);


            $('#progress .progress-bar').css(
                'width',
                progress + '%'
            );
        }
    });


    $(form_selector).delegate(".js-file-delete", "click", function (e) {
        e.preventDefault();

        var fileContainer = $(this).closest('.js-file');
        formUtils.removeFile(fileContainer);
    });


    // bind all color pickers
    if ($.minicolors) {
        $(form_selector + ' .js-colorpicker').minicolors({
            defaultValue: '#FF9900',
            theme: 'bootstrap'
        });
    }

/*    $(form_selector + ' .js-autocomplete').each(function (key, elem) {
        initAutocomplete(elem);
    });
*/
    // bind all date pickers

    $(form_selector + ' .js-datepicker').datetimepicker({
        //format: 'dd/mm/yyyy',
        pickTime: false,
        language: 'fr'
        //todayHighlight: true,
        //autoclose: true
    });

    $(form_selector + ' .js-datetimepicker').datetimepicker({
        //format: 'dd/mm/yyyy',
        language: 'fr'
        //todayHighlight: true,
        //autoclose: true
    });

    $(form_selector + ' .js-timepicker').datetimepicker({
        //format: 'dd/mm/yyyy',
        pickDate: false,
        language: 'fr'
        //todayHighlight: true,
        //autoclose: true
    });

    // bind all rich selector
    $(form_selector + ' .js-selector').select2({
        minimumResultsForSearch: 20
    });
    $(form_selector + ' .js-twitteraccount').keydown(function () {
        if ($(this).val() != '' && $(this).val().indexOf('@') < 0) {
            $(this).val("@" + $(this).val());
        }
    });
    $(form_selector + ' .js-hashtag').keydown(function () {
        if ($(this).val() != '' && $(this).val().indexOf('#') < 0) {
            $(this).val("#" + $(this).val());
        }
    });

    // bind all Typeahead
    Typeahead.bindAll($(form_selector));

    $(form_selector + ' .js-fontawesome').select2({
        templateResult: function (state) {
            if (!state.id) {
                return state.text;
            }
            return $('<span><i class="fa ' + state.element.value.toLowerCase() + '"> </i> ' + state.element.value.toLowerCase() + '</span>');
        },
        templateSelection: function (state) {
            if (!state.id) {
                return state.text;
            }
            return $('<span><i class="fa ' + state.element.value.toLowerCase() + '"> </i> ' + state.element.value.toLowerCase() + '</span>');
        }
    });


    // bind cropper widget

    $(form_selector + ' .js-cropper-widget').each(function (idx, elem) {

        elem = $(elem);

        elem.find('.js-cropper-button').on('click', function(event) {
            elem.find('.js-cropper-block').show();

            var input = elem.find('input');
            var img = elem.find('img.cropper');
            var preview = elem.find('img.preview');
            var data = elem.data('value');

            img.cropper({
                aspectRatio: 16 / 9,
                viewMode: 1,
                movable: false,
                rotatable: false,
                scalable: false,
                zoomable: false,
                preview: '.js-cropper-preview',
                crop: function (e) {

                    data.x = Math.max(0, parseInt(e.x, 10));
                    data.y = Math.max(0, parseInt(e.y, 10));
                    data.width = parseInt(e.width, 10);
                    data.height = parseInt(e.height, 10);

                    input.val(JSON.stringify(data));

                }
            });

            $(this).off(event);
        });
    });


    // bind phone widgets
    $(form_selector+' .js-phone').intlTelInput({
        utilsScript: '/bundles/easycore/lib/intl-tel-input-3.7.1/js/utils.js'
    });


    $(form_selector + ' .js-uploader').uploader();

    $(form_selector + ' .js-collector').collector();

    $(form_selector + ' .js-rating').rating();


    // focus on first field
    if (kw.focus == undefined || kw.focus)
        $(form_selector + ' .form-group:first input').focus();

    // autosave form
    if (kw.autosave) {

        var submit = function (e) {
            Net.submit($(form_selector), null, true);
        };

        $(form_selector).on('change', "input", submit);
        $(form_selector).on('change', "select", submit);

    }

    $(form_selector).find('.select-multiple').selectMultiple({allowHTML: true});

    if (additonnal_call)
        additonnal_call(form_selector,kw);

    $(form_selector).on('change', '[data-autobind="1"]', function() {
        var id = $(this).attr('id');
        var targets = $(this).data('autobind-targets');
        Net.submit($(form_selector), function() { 
            $('#'+ id).focus();
            $(form_selector).find('.select-multiple').selectMultiple({allowHTML: true});
        }, true, true, targets);

    });
    
   $(form_selector).on('click', '[data-autobind-click=1]', function(){
        var id = $(this).attr('id');
        var targets = $(this).data('autobind-targets');
        Net.submit($(form_selector), function() { 
            $('#'+ id).focus();
            $(form_selector).find('.select-multiple').selectMultiple({allowHTML: true});
        }, true, true, targets);
   });

    $('.js-autocomplete').each(function (key, elem) {
        initAutocomplete(elem);
    });


};


(function ($) {
    $.fn.uploader = function () {
        this.each(function () {
            var that = $(this);
            that.find('.js-file-path').change(function () {
                that.find('.js-file-text').html($(this).val());
                that.find('.js-file-deleted').val(0);
                that.find('.js-file-active').removeClass('hidden');
            });
            that.find('.js-file-deleter').click(function () {
                that.find('.js-file-deleted').val(1);
                that.find('.js-file-active').addClass('hidden');
                that.find('.js-file-text').html('...');
            });
        });
    }

    $.fn.collector = function () {
        this.each(function () {
            var name = $(this).attr('id');
            //new Collection('#' + name);
        });
    }
}(jQuery));





