//var $        = require('jquery');
var Net = require('core/Net');
var bindCard = require('./bindCard');
var Modal = require('components/Modal');
var ModalFrame = require('components/ModalFrame');
var Sidebar = require('components/Sidebar');

module.exports = function () {

    var body = $('#body');

    var element = body.find('table.table-fixedheader');
    if (element.floatThead){
        element.floatThead().floatThead('reflow');
    }

    body.on('click', 'a[data-confirm]', function (e) {
        return true;
        e.preventDefault();
        e.stopImmediatePropagation();
        var url = $(this).attr('href');
        var confirm_msg = $(this).data('confirm');


        swal({
            title: "Are you sure?",
                text: "",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes, delete it!",
            closeOnConfirm: false
        }, function(){

            event.target.dispatchEvent(event);
            swal.close();
        });
    });

    body.on('click', 'a[data-src="xhr"]', function (e) {
        e.preventDefault();
        e.stopPropagation();

        var url = $(this).attr('href');
        var confirm = $(this).attr('data-confirm');
        if(confirm)
        {

            swal({
                title: confirm,
                text: "",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Oui",
                cancelButtonText: "Non",
                closeOnConfirm: false
            }, function(){
                Net.GET(url);
                swal.close();
            });
        } else {
            Net.GET(url);
        }


    });

    body.on('click', 'a[data-src="modal-frame"]', function (e) {
        e.preventDefault();
        e.stopPropagation();

        var url = $(this).attr('href');
        ModalFrame.instance.display(url);

    });

    body.on('click', 'tr[data-src="xhr"] a', function (e) {
        e.stopPropagation();
    });

    body.on('click', 'tr[data-src="xhr"]', function (e) {
        e.preventDefault();
        e.stopPropagation();

        var url = $(this).attr('data-href');
        var confirm_msg = $(this).data('confirm');
        var ok = !confirm_msg || confirm(confirm_msg);

        if (ok) {
            Net.GET(url);
        }

    });

    body.on('submit', 'form[data-src="xhr"]', function (e) {
        e.preventDefault();

        if (typeof CKEDITOR != 'undefined') {
            for (instance in CKEDITOR.instances) {
                CKEDITOR.instances[instance].updateElement();
            }
        }

        Net.submit($(this));
    });

    $('.dropdown-toolbar').on({
        "shown.bs.dropdown": function() { this.closable = false; },
        "click":             function(e) {
            var target = $(e.target);
            if(target.hasClass("fa"))
                this.closable = true;
            else
                this.closable = false;
        },
        "hide.bs.dropdown":  function() { return this.closable; }
    });



    // bind html fragment
    bindCard(body);

    // init components
    Modal.initialize('modal');
    ModalFrame.initialize('body');
    Sidebar.initialize();

};
