global.App = require('app/App');

var CommonCtrl = require('module/CommonController');
var SearchFormCtrl = require('module/SearchFormController');
var GalleryCtrl = require('module/GalleryController');
var DocumentCtrl = require('module/DocumentController');

CommonCtrl.bindCheckbox();
CommonCtrl.bindComponent();
SearchFormCtrl.bindSearchForms();
GalleryCtrl.bind();
DocumentCtrl.bind();

// chrome compatibility too
App.Net.ie8_compatibility = true;

global.CommonCtrl = CommonCtrl;
global.SearchFormCtrl = SearchFormCtrl;