
var bindPage   = require('bindings/bindPage');
var bindForm   = require('bindings/bindForm');
var formUtils  = require('bindings/formUtils');
var Net        = require('core/Net');
var Log        = require('core/Log');

// register message handlers
require('core/MessageHandlers');

module.exports = {

    initialize: function (data, debug){
        bindPage();
        Log.debug = debug == undefined ? true : debug;
        if (data){
            Net.handleResponse(data);
        }
    },

    // Net utils

    GET:       Net.GET,
    POST:      Net.POST,
    submit:    Net.submit,

    // services

    Net:        Net,
    Poller:     require('services/Poller'),
    Templating: require('services/Templating'),

    bindForm:   bindForm,
    formUtils:  formUtils,

    // components

    Modal:      require('components/Modal'),
    ModalFrame: require('components/ModalFrame'),

    Table:      require('components/Table'),
    Tree:       require('components/Tree'),
    Gallery:    require('components/Gallery'),

    Wizard:     require('components/Wizard')

};
