//var $               = require('jquery');
var _ = require('lodash');
//var toastr          = require('toastr');

var Modal = require('components/Modal');

var registry = require('./MessageHandlerRegistry');

function handleRequest(kw, cb, disable_spinner) {

    if (!disable_spinner)
        $("#spinnerbar").show();

    var params = _.assign({

        success: function (response, status) {
            var response = $.parseJSON(response);
            var result = handleResponse(response);
            if (cb) {
                cb(result);
            }
        },
        error: function (response, status, error) {
            if (!disable_spinner)
                $("#spinnerbar").hide();

            toastr.warning(error);
            var err = status + ', ' + error;
            console.log("Request Failed: " + err);
            //Modal.instance.displayError(response.responseText);
        },
        complete: function (response, status) {
            if (!disable_spinner)
                $("#spinnerbar").hide();

            var token = response.getResponseHeader('x-debug-token');
            if (token) {
                var url = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + '/app_dev.php/_wdt/' + token;
                $.get(url, function (data) {
                    $('.sf-toolbar').empty();
                    $('.sf-toolbar').append(data);
                });
            }

        }

    }, kw);

    $.ajax(params);


}


function handleResponse(data) {
    var result = null;

    if (data.ok) {

        _.each(data.messages, function (message) {
            var message_result = registry.handle(message);
            if (message_result)
                result = message_result;
        });

    } else {
        toastr.warning(data.error || 'Invalid response');
    }

    return result;

}


function jsonifyForm(form) {
    // serialize form
    var data = {};
    var formData = form.serializeArray();

    _.each(formData, function (elem) {
        if (data[elem.name]) {
            if (!data[elem.name].push) {
                data[elem.name] = [data[elem.name]];
            }
            data[elem.name].push(elem.value || '');
        } else {
            data[elem.name] = elem.value || '';
        }
    });

    return data;
}


var api = {
    ie8_compatibility: false,

    handleResponse: handleResponse,
    GET: function (url, cb, disable_spinner) {

        handleRequest({
            type: 'GET',
            url: url
        }, cb, disable_spinner);

    },
    POST: function (url, data, cb, disable_spinner) {
        handleRequest({
            type: 'POST',
            url: url,
            data: JSON.stringify(data),
            processData: false
        }, cb, disable_spinner);

    },
    submit: function (form, cb, disable_spinner, autobind, autobindTargets) {

        autobind = (autobind) ? 1 :  0;
        var url = form.attr('action');
        var method = form.attr('method');
        if (typeof method === typeof undefined || method === false) {
            method = 'POST';
        }

        if(api.ie8_compatibility)
        {
            var data = jsonifyForm($(form[0]));
            handleRequest({
                type: method,
                url: url,
                data: data,
                processData: true
                //contentType: false
            }, cb, disable_spinner);

        }else{
            var data = new FormData(form[0]);
            handleRequest({
                type: method,
                beforeSend: function (request)
                {
                    request.setRequestHeader("x-autobind", autobind);
                    if(autobindTargets)
                        request.setRequestHeader("x-autobind-targets", autobindTargets);

                },
                url: url,
                data: data,
                processData: false,
                contentType: false
            }, cb, disable_spinner);

        }
    }

};


module.exports = api;

