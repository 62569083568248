var ctrl = function () {
};

ctrl.bind = function () {
    var $body = $('body');
    var $scope = $('.video_list_js');

    $body.on('click', '.link_gallery_js', function () {
        var $form = $('.filter_form_js', $scope);
        $('.input_gallery_js', $form).val($(this).attr('data-id'));
        $form.submit();
    });

    // bind download link
    $body.on('click', '[data-big-image]', function () {
        var link = $(this).data('id');
        var downloadForm = $('#form_picture_download');
        if (downloadForm)
            downloadForm
                .find('#pictureId')
                .val(link);
    });
};

module.exports = ctrl;