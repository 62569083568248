var ctrl = function () {
};

ctrl.bindCheckbox = function ($scope) {
    if (typeof $scope == 'undefined') {
        var $scope = $('body');
    }

    var $checkboxContainer = $('.checkboxStyle', $scope);

    $checkboxContainer.each(function () {
        var $this = $(this);

        var $checkbox = $('input[type="checkbox"]', $this);
        var $square = $('.isCheckboxStyle', $this);

        if ($checkbox.prop('checked')) {
            $this.addClass('isChecked');
        } else {
            $this.removeClass('isChecked');
        }

        $checkbox.change(function () {
            if ($(this).prop('checked')) {
                $this.addClass('isChecked');
            } else {
                $this.removeClass('isChecked');
            }
        });

        $square.click(function () {
            $checkbox.prop('checked', !$checkbox.prop('checked'));
            if ($checkbox.prop('checked')) {
                $this.addClass('isChecked');
            } else {
                $this.removeClass('isChecked');
            }
            $checkbox.trigger("change");
        });
    });
};

ctrl.bindComponent = function () {
    var $body = $('body');

    $body.on('click', '[data-src-href]', function (e) {
        e.preventDefault();
        window.location.href = $(this).attr('data-src-href');
    });

    $body.on('click', '[data-src="shared"]', function (e) {
        e.preventDefault();
        window.open($(this).data('href'), '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
    });
};

ctrl.download = function (link, name) {
    if (!name)
        name = link;
    url = link
        .replace(/^data:image\/[^;]/, 'data:application/octet-stream')
    ;

    var a = $("<a>")
        .attr("href", url)
        .attr("download", name)
        .css('display', 'none')
        .appendTo("body");

    a[0].click();

    a.remove();
};

module.exports = ctrl;