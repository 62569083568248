var ImageUpload = require('components/ImageUpload');
var Typeahead = require('components/Typeahead');
var formUtils = require('bindings/formUtils');
var dragula = require('dragula');

var formCollection = function() {};

formCollection.bindAll = function(container) {
    container = container.find('.js-collector');

    // simple collection initializer
    // call manually in bo pages

    function bindRow(node) {

        node.find('.js-del-row').on('click', function (e) {
            e.preventDefault();
            node.remove();
        });

        node.find('.js-trans-flag li a').click(function (e) {
            e.preventDefault();
            locale = $(this).closest('li').attr('data-value');
            target = $(this).closest('tr');
            target.find(' .js-flag-options').addClass('hidden');
            target.find(' input[data-option="' + locale + '"]').closest('.js-flag-options').removeClass('hidden');
            target.find(' .js-trans-flag a.nav img').attr('src', $(this).find('img').attr('src'));
        });

        node.find('select.js-selector').select2();

        ImageUpload.initialize(node);


        node.find('.js-fileupload').fileupload({
            dataType: 'json',
            formData: {},
            maxFileSize: 5000000,
            add: function (e, data) {
                var fileContainer = $(this).closest('.js-file');
                formUtils.uploadStarted(fileContainer);
                data.submit();
            },
            done: function (e, data) {
                var fileContainer = $(this).closest('.js-file');
                $.each(data.result.files, function (index, file) {
                    formUtils.addFile(fileContainer, file);
                });

            },
            fail: function (e, data) {
                var fileContainer = $(this).closest('.js-file');
                formUtils.uploadFinished(fileContainer);
            },
            progressall: function (e, data) {
                return;

                var progress = parseInt(data.loaded / data.total * 100, 10);
                updateProgressFile(fileId);


                $('#progress .progress-bar').css(
                    'width',
                    progress + '%'
                );
            }
        });

    }

    var nb_rows = container.find('tbody tr').length;

    // bind del rows buttons
    container.find('tbody tr').each(function () {
        var row = $(this);
        bindRow(row);
    });

    // bind add button
    container.find('.js-add-row').on('click', function (e) {
        e.preventDefault();

        nb_rows += 1;

        var node = $(this);
        var prototype = window[node.attr('data-prototype')];
        var row = $(prototype.replace(/__name__/g, nb_rows));
        node
            .closest('table')
            .find('tbody')
            .append(row);

        bindRow(row);

        row.find('.js-autocomplete').each(function (key, elem) {
            initAutocomplete(elem);
        });
        Typeahead.bindAll(row);
    });

    // bind draggable
    container.each(function (idx, elem) {
        var $elem = $(elem);
        if ($elem.hasClass('draggable')) {

            var dragulaContainer;

            if (elem.hasAttribute('data-dragula-container')) {
                dragulaContainer = $elem.find('.' + $elem.data('dragula-container'))[0];
            } else {
                dragulaContainer = $elem.find('tbody')[0];
            }

            dragula([dragulaContainer], {
                mirrorContainer: dragulaContainer,
                moves: function (el, container, handle) {
                    if (elem.hasAttribute('data-dragula-handler')) {
                        return handle.classList.contains($elem.data('dragula-handler'));
                    } else {
                        return true;
                    }
                }
            });
        }
    });

    container.closest('form').on('submit', function() {
        // calcul order before submit
        container.find('.js-input-order').each(function(idx, elem) {
            $(elem).val(idx);
        });
    });
};

module.exports = formCollection;