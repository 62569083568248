//var $ = require('jquery');

function ModalFrame(container_id){
    this.container_id = container_id;
    this.view = null;
};

ModalFrame.prototype = {

    display: function (url){
        if (!this.view){
            this.initFrame(url);
        }else{
            this.setFrameSrc(url);
        }
        this.view.modal('show');
    },

    close: function (){
        this.view.modal('hide');
    },

    //

    setFrameSrc: function (url){
        var iframe = this.view.find('iframe');
        iframe.attr('src', url);

        iframe.show();
    },

    initFrame: function (url){

        this.view = $('<div class="modal fade" style="z-index: 20000"><iframe style="background-color: white; border: 1px solid #999; margin-left: 4%; margin-top: 4%; width: 92%; height: 92%;"/></div>');

        var iframe = this.view.find('iframe');
        iframe.attr('src', url);

        $('#' + this.container_id).append(this.view);
    }

};

// singleton

ModalFrame.initialize = function (container_id){
    if (!ModalFrame.instance){
        ModalFrame.instance = new ModalFrame(container_id);
    }
};

module.exports = ModalFrame;
